<template>

    <div class="overflow-y-auto flex flex-col h-screen" v-if="initialized" v-cloak>
        <div class="flex-grow p-8 text-2xl flex flex-col h-full-no-buttons">
            <div class="flex justify-between items-start mb-8">
                <h2 class="text-4xl font-bold flex justify-start items-center move-table-title">
                    <div class="mr-4">{{$t('translations.views.pos.table.tables-move.title')}}:</div>
                    <div class="text-sm" v-if="table_from">{{ table_from.attributes.name }}</div>
                    <svg v-if="table_to" class="my-1 mx-5 w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                    <div v-if="table_to" class="text-sm ml-2 inline">
                        {{ table_to.attributes.name }}
                    </div>
                </h2>
                <a @click="close()" class="screen-close">
                    <svg class="w-14 h-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </a>
            </div>

            <!-- table selection -->
            <div class="grid grid-cols-5 gap-5 tables-move-wrapper" v-if="([1, 2]).includes(step)">
                <div class="col-span-1 text-xl tables-cat-wrapper">
                    <div class="mb-3" v-for="(area, index) in areas" v-bind:key="index">
                        <span class="w-full inline-flex rounded-sm  shadow-sm">
                            <a @click="areaSelect(area)" :class="{'selected': this.area.id == area.id}"
                               class="button button-blur button-wide">
                                {{ area.attributes.name }}
                            </a>
                        </span>
                    </div>

                    <div class="mb-3" v-if="virtualtables.length > 0">
                        <span class="w-full inline-flex rounded-sm  shadow-sm">
                            <a @click="areaSelect('virtualtables')" :class="{'selected ': area.id == 'virtualtables'}"
                               class="button button-blur button-wide">
                               {{$t('translations.views.pos.table.tables-move.virtual-tables')}}
                            </a>
                        </span>
                    </div>
                </div>

                <!-- default layout -->
                <div class="col-span-4">
                    <!-- default layout -->
                    <div v-if="!area.attributes.pos_map_active">

                        <!-- tables -->
                        <div v-if="area.id != 'virtualtables'" class="grid grid-cols-5 gap-4 table-overview">
                            <div class="col-span-1" v-for="(table, index) in area.relationships.tables"
                                 v-bind:key="index">
                                <li :class="{'opacity-25': table.attributes.merged }" class="col-span-1 tile">

                                    <div @click="tableSelect(table)" class="px-1 truncate content">
                                        <div class="content">
                                            <span class="table-name">{{ table.attributes.name }}</span>
                                            <span class="ml-3"
                                                  v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary }})</span>
                                        </div>
                                    </div>

                                    <div @click="tableSelect(table)"
                                         :class="[table.attributes.total > 0 ? 'bg-red-500' : 'bg-green-500' ]"
                                         class="price">
                                        <currency/>{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                    </div>
                                    <div class="reset hidden">
                                        <modal-table-reset-spending :table="table" />
                                    </div>
                                </li>
                            </div>
                        </div>

                        <!-- virtualtables -->
                        <div v-if="area.id == 'virtualtables'" class="list-primary clients">
                            <!-- <div class="bg-shadow text-left overflow-auto border h-70vh" @click="setField()"> -->
                            <ul>
                                <li class="border-b" v-for="(table, index) in area.relationships.tables"
                                    v-bind:key="index">
                                    <a @click="tableSelect(table)" class="block">
                                        <div class="flex items-center gap-5 justify-between">
                                            <div class="flex justify-start text-base font-bold flex-wrap">
                                                <div class="font-medium">{{ table.attributes.name }}</div>
                                                <span class="ml-3"
                                                      v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary }})</span>
                                                <span class="ml-3 font-normal italic" v-if="table.attributes.merged">({{$t('translations.views.pos.table.tables-move.merged')}})</span>
                                            </div>
                                            <div class="font-bold text-base price"
                                                 :class="[table.attributes.total > 0 ? 'text-red-500' : 'text-green-500' ]">
                                                <currency/>{{
                                                    vueNumberFormat(table.attributes.total ? table.attributes.total : 0)
                                                }}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- map layout -->
                    <div v-if="area.attributes.pos_map_active" >
                        <!-- map layout -->
                        <div v-if="terminal.layout === 'mobiledirect' || terminal.layout === 'mobileordering'" class="overflow-y-auto map-list">
                            <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                                 @click="tableSelect(table)"
                                 class="list-item flex justify-between mb-2"
                            >
                                <div class="truncate">{{ table.attributes.name }}
                                    <div v-if="table.attributes.name_temporary">
                                        ({{ table.attributes.name_temporary }})
                                    </div>
                                </div>
                                <div class="italic">
                                    {{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                </div>
                            </div>
                        </div>

                        <div v-else class="map border rounded-sm relative" :style="
                        'background:url(' + area.attributes.pos_map_image + ');' +
                        'width: calc(760px * ' + terminal.map_factor + ');' +
                        'height: calc(440px * ' + terminal.map_factor + ');' +
                        'background-size: calc(760px * ' + terminal.map_factor + ') calc(440px * ' + terminal.map_factor + ') !important;'


                        ">
                            <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                                 @click="tableSelect(table)"
                                 :class="{'opacity-50 cross': table.attributes.merged, 'rounded-full': table.attributes.map_shape == 'round', 'bg-red-500': table.relationships.lines.length > 0}"
                                 class="bg-gray-300 table text-xs cursor-pointer absolute border text-center"
                                 :style="'width:calc(' + table.attributes.map_width + ' * ' + terminal.map_factor + 'px); height:calc(' + table.attributes.map_height + ' * ' + terminal.map_factor + 'px); top: calc(' + table.attributes.map_positiontop + ' * ' + terminal.map_factor + 'px); left: calc(' + table.attributes.map_positionleft + ' * ' + terminal.map_factor + 'px)'">
                                <div class="truncate h-full flex justify-center items-center">
                                    <div>
                                        <div class="font-bold">{{ table.attributes.name }}
                                            <div v-if="table.attributes.name_temporary">
                                                ({{ table.attributes.name_temporary }})
                                            </div>
                                        </div>
                                        <div class="italic">
                                            {{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step === 3" class="flex justify-between relocate-table flex-grow overflow-y-hidden">
                <div class="flex-grow flex flex-col overflow-y-auto parent-table" >
                    <div class="font-bold mb-2 table-name" >
                        {{ table_from.attributes.name }}
                    </div>

                    <div class="list-primary overflow-y-auto" v-if="table_from.relationships.lines.length">


                        <div @click="lineSelect(line)"
                             :class="{'bg-gray-500': line.selected, 'line-through': lineIsMoved(line)}"
                             class="grid grid-cols-12 py-1 flex items-start"
                             v-for="(line, index) in table_from.relationships.lines" :key="index">
                            <div class="col-span-2 flex justify-center pr-2">
                                <div class="leading-5 text-base font-bold">{{
                                        parseFloat(line.attributes.quantity)
                                    }}
                                </div>
                            </div>
                            <div class="col-span-7 text-left">
                                <div class="text-sm font-bold leading-5 truncate"
                                     :class="{'ml-3 italic': line.attributes.addon}">{{ line.attributes.description }}
                                </div>
                                <div class="italic" v-for="(value, description) in line.attributes.kitchen_groceries"
                                     :key="description">
                                    {{ description }}: {{ value }}
                                </div>
                            </div>
                            <div class="col-span-3 flex items-center justify-end font-bold text-base">
                                <div class="mr-1">
                                    <div>{{ vueNumberFormat(line.attributes.total) }}</div>
                                    <div class="text-xs text-gray-300" v-if="line.attributes.discount_amount > 0">
                                        (-{{ vueNumberFormat(line.attributes.discount_amount) }})
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-left text-xl">
                        {{$t('translations.views.pos.table.tables-move.no-items')}}.
                    </div>
                </div>
                <div class="px-8 mt-16 move-icons">
                    <div class="flex justify-center mb-5">
                        <div @click="moveSelected('ltr')" class="h-15 w-15 button button-blur">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="60px" width="60px">
                                <path
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="flex justify-center mb-5">
                        <div @click="moveSelected('rtl')" class="h-15 w-15 button button-blur">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="60px" width="60px">
                                <path fill-rule="evenodd"
                                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                      clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div class="flex justify-center mb-5">
                        <div @click="moveSelected('ltr', true)" class="h-15 w-15 button button-blur">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="60px" width="60px">
                                <path fill-rule="evenodd"
                                      d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                      clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                      d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                      clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <div class="flex justify-center mb-5">
                        <div @click="moveSelected('rtl', true)" class="h-15 w-15 button button-blur">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="60px" width="60px">
                                <path fill-rule="evenodd"
                                      d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                      clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="flex-grow flex flex-col overflow-y-auto parent-table">

                    <div class="font-bold mb-2 table-name">
                        {{ table_to.attributes.name }}
                    </div>
                    <div class="list-primary" v-if="table_to.relationships.lines.length">
                        <div class="grid grid-cols-12 py-1 flex items-start text-gray-500"
                             v-for="(line, index) in table_to.relationships.lines" :key="index">
                            <div class="col-span-2 flex justify-center pr-2">
                                <div class="leading-5 text-base font-bold">{{
                                        parseFloat(line.attributes.quantity)
                                    }}
                                </div>
                            </div>
                            <div class="col-span-7 text-left">
                                <div class="text-sm font-bold leading-5 truncate"
                                     :class="{'ml-3 italic': line.attributes.addon}">{{ line.attributes.description }}
                                </div>
                                <div class="italic text-xs"
                                     v-for="(value, description) in line.attributes.kitchen_groceries"
                                     :key="description">
                                    {{ description }}: {{ value }}
                                </div>
                            </div>
                            <div class="col-span-3 flex items-center justify-end font-bold text-base">
                                <div class="mr-1">
                                    <div>{{ vueNumberFormat(line.attributes.total) }}</div>
                                    <div class="text-xs text-gray-300" v-if="line.attributes.discount_amount > 0">
                                        (-{{ vueNumberFormat(line.attributes.discount_amount) }})
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-left text-xl">
                        {{$t('translations.views.pos.table.tables-move.no-items')}}.
                    </div>

                    <div @click="lineSelect(line)" :class="{'bg-gray-500': line.selected}"
                         class="grid grid-cols-12 py-1 flex items-start" v-for="(line, index) in lines" :key="index">
                        <div class="col-span-2 flex justify-center pr-2">
                            <div class="leading-5 text-base font-bold">{{ parseFloat(line.attributes.quantity) }}</div>
                        </div>
                        <div class="col-span-7 text-left">
                            <div class="text-sm font-bold leading-5 truncate"
                                 :class="{'ml-3 italic': line.attributes.addon}">{{ line.attributes.description }}
                            </div>
                            <div class="italic text-xs"
                                 v-for="(value, description) in line.attributes.kitchen_groceries" :key="description">
                                {{ description }}: {{ value }}
                            </div>
                        </div>
                        <div class="col-span-3 flex items-center justify-end font-bold text-base">
                            <div class="mr-1">
                                <div>{{ vueNumberFormat(line.attributes.total) }}</div>
                                <div class="text-xs text-gray-300" v-if="line.attributes.discount_amount > 0">
                                    (-{{ vueNumberFormat(line.attributes.discount_amount) }})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- {{table_from}} -->
            <!-- {{table_to}} -->
        </div>

        <footer class="button-bar text-xl">
            <div class="flex justify-end space-x-4">
                <a v-if="table_from" @click="reset()" class="button button-danger">
                    {{$t('translations.views.pos.table.tables-move.reset')}}
                </a>

                <a :disabled="saving" v-if="step == 3" @click="submit()" class="button button-filled">
                    {{$t('translations.views.pos.table.tables-move.confirm')}}
                    <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </a>
            </div>
        </footer>
    </div>

</template>

<script>
import axios from 'axios';

// mixins
import TablesHelper from '../../../mixins/tables.helper.js';

export default {
    name: 'TablesMove',
    mixins: [TablesHelper],
    props: ['area_id', 'table_id'],
    data () {
        return {
            step: 1, // 1 -> select table from, 2-> select table to, 3 -> select items
            initialized: false,
            saving: false,
            area: null,
            areas: [],
            virtualtables: [],
            table_from: null,
            table_to: null,

            lines: [],
        };
    },
    mounted () {
        this.area = null;
        this.areas = [];
        this.virtualtables = [];
        this.lines = [];

        this.tablesGet();
    },

    methods: {
        close(tableId = false) {

            if (this.terminal.layout == 'mobileordering' && (this.table_id || tableId)) {
                this.$router.push({name: 'mobilepos.table', params: {
                    area_id: this.area_id,
                    table_id: this.table_id || tableId,
                }});
            } else if (this.terminal.layout == 'mobileordering') {
                this.$router.push({name: 'mobilepos'})
            } else {
                this.$router.push({name: 'pos'});
            }
        },

        // submit
        submit () {
            this.saving = true;

            axios.post('/tables/move', {
                table_from_id: this.table_from.id,
                table_to_id: this.table_to.id,
                lines: this.lines,
            }).then(() => {

                // notify
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Items verplaats",
                    text: "De items zijn verplaatst naar tafel " + this.table_to.attributes.name,
                }, 1000);

                // redirect to to tables
                this.close(this.table_to.id);
            }).catch(error => {
                console.log(error)
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => this.saving = false);
        },

        // select table
        tableSelect (table) {
            if (table.attributes.merged) {
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Tafel samengevoegd",
                    text: "Deze tafel is samengevoegd en kan niet worden geselecteerd",
                }, 2000);
            }

            if (table.attributes.active_clerk_restriction == true && table.attributes.clerk_id && table.attributes.clerk_id != this.clerk.id) {
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Tafel bezet",
                    text: "Deze tafel is bezet door een andere medewerker",
                }, 2000);
            }

            if (this.step == 1) {
                this.table_from = JSON.parse(JSON.stringify(table));
                this.step = 2;
            } else if (this.step == 2) {
                this.table_to = JSON.parse(JSON.stringify(table));
                this.step = 3;
            }
        },

        // check if the line is moved
        lineIsMoved (fromline) {
            let moved = false;
            this.lines.forEach(function (line) {
                if (fromline.id == line.id) {
                    moved = true;
                }
            });
            return moved;
        },

        // set status of line to selected
        lineSelect (line) {
            line.selected = !line.selected;
        },

        // move the selected lines
        moveSelected (direction, move_all = false) {

            // let self = this
            let quantity;
            let lines_from;
            let lines_to;
            let lines_delete = [];

            // set lines for direction
            if (direction == 'ltr') {
                lines_from = this.table_from.relationships.lines;
                lines_to = this.lines;
            } else if (direction == 'rtl') {
                lines_from = this.lines;
                lines_to = this.table_from.relationships.lines;
            }

            lines_from.forEach((from_line, index) => {
                if (from_line.selected || move_all) {

                    quantity = 1;

                    // move all items
                    if (move_all) {
                        quantity = from_line.attributes.quantity;
                    }

                    // set quantity
                    from_line.attributes.quantity = from_line.attributes.quantity - quantity;
                    from_line.attributes.total = from_line.attributes.quantity * from_line.attributes.rate;

                    let exists = false;

                    lines_to.forEach(movedline => {
                        if (movedline.id == from_line.id) {
                            exists = true;
                            movedline.attributes.quantity = movedline.attributes.quantity + quantity;
                            movedline.attributes.total = movedline.attributes.quantity * movedline.attributes.rate;
                        }
                    });

                    // not exists in to_line, add the line the array of lines
                    if (!exists) {
                        let line = JSON.parse(JSON.stringify(from_line));

                        line.attributes.quantity = quantity;
                        line.attributes.total = line.attributes.quantity * line.attributes.rate;

                        lines_to.push(line);
                    }

                    from_line.moved = true;

                    // remove line if quantity == 0
                    if (from_line.attributes.quantity <= 0) {
                        from_line.moved = false;
                        lines_delete.push(index);
                        // lines_from.splice(index, 1)
                    }
                }
            });

            // delete lines
            lines_delete.reverse().forEach(line => {
                lines_from.splice(line, 1);
            });
        },

        // reset values
        reset () {
            this.table_from = null;
            this.table_to = null;
            this.step = 1;
            this.lines = [];
        },
    },
};
</script>
