import axios from 'axios'


export default {
    methods: {
        async tablesGet(location_id = null) {
            this.areas = []

            const url = location_id ? '/areas/tables?tables_location_id=' + location_id + '/tables' : '/areas/tables';
            console.log(url)
            await axios.get(url).then(response => {
                this.areas = response.data.areas.data
                this.virtualtables = response.data.virtualtables.data

                // filter tables for clerk
                if (this.clerk && this.clerk.area_ids && this.clerk.area_ids.length > 0) {
                    this.areas = this.areas.filter(area => {
                        return this.clerk.area_ids.indexOf(area.id) >= 0;
                    });
                }

                // has areas
                if (this.areas.length) {

                    // select first area
                    this.areaSelect(this.areas[0])

                    // terminal has a default area
                    if (this.terminal.default_area_id) {
                        let self = this
                        this.areas.forEach(area => {
                            if (area.id == this.terminal.default_area_id) {
                                self.areaSelect(area)
                            }
                        });
                    }
                }
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.initialized = true
            })
        },

        async tablesGetRefresh() {
            if (this.areas.length == 0) {
                return
            }
            axios.get('/areas/tables').then(response => {
                this.areas = response.data.areas.data
                this.virtualtables = response.data.virtualtables.data
                let self = this
                this.areas.forEach((area, index) => {
                    if (area.id == this.area.id) {
                        self.areaSelect(this.areas[index])
                    }
                });
            })
        },

        async locationsGet() {
            this.locations = []

            await axios.get('/tables/locations').then(response => {
                this.locations = response.data.data
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            })
        },

        changeLocation(event) {
            this.tablesGet(event.target.value)
        },

        async areaSelect(area) {
            if (area == 'virtualtables') {
                area = {
                    id: 'virtualtables',
                    attributes: { pos_active_map: false},
                    relationships: { tables: this.virtualtables }
                }
            } else if (area == 'tablelist') {
                let tables;
                await axios.get('/tables' + (this.tablelist_opentables == true ? '?open=true' : '')).then(response => {
                    tables = response.data.data
                }).catch(() => {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                })
                area = {
                    id: 'tablelist',
                    attributes: { pos_active_map: false},
                    relationships: { tables: tables }
                }
            }

            this.area = area;
        },

        setOpenTables() {
            this.tablelist_opentables = !this.tablelist_opentables
            this.areaSelect('tablelist')
        }
    },
    computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },

		clerk() {
			return this.$store.getters['transaction/clerk'];
        },
    }
}
